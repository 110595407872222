import { BlockType } from "@prisma/client";

export const blockType = "embed-block" as const;
export const blockTypeDbName = BlockType.Embed;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;
