import "zod-openapi/extend";

import {
  BlockBaseSchema,
  BlockIdSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import {
  NavigateActionSchema,
  NotificationActionSchema,
  OpenExternalUrlActionSchema,
} from "block-system/types/common";
import { FieldBlockSchema } from "../Field/schema";
import { z } from "zod";
import { blockType } from "./constants";
import { ActionType } from "block-system/types";

// Form actions
// i.e. Actions that are stored in the form block config in the page content
// -----------------------------------------------------------------------------

export const FormBlockActionSchema = z.discriminatedUnion("type", [
  NavigateActionSchema,
  NotificationActionSchema,
  OpenExternalUrlActionSchema,
]);

export type FormBlockAction = z.infer<typeof FormBlockActionSchema>;

export type FormBlockActionType = FormBlockAction["type"];

// Form block
// -----------------------------------------------------------------------------

export const StorageTypeSchema = z.enum(["tables"]);

export type StorageType = z.infer<typeof StorageTypeSchema>;

export const ConfirmationActionTypes: (ActionType | FormBlockActionType)[] = [
  "navigate",
  "notification",
  "openExternalUrl",
];

export const FormBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: BlockIdSchema.optional(),
    submitText: z.string(),
    storageType: StorageTypeSchema.optional(),
    storageId: z.string().optional(),
    storageCreatedFromInterfaces: z.boolean().optional(),
    triggers: z.array(FormBlockActionSchema).refine(
      (actions) => {
        const confirmationActions = actions.filter((t) =>
          ConfirmationActionTypes.includes(t.type)
        );
        return (
          confirmationActions.length >= 1 &&
          !ConfirmationActionTypes.some(
            (item) => actions.filter((t) => t.type === item).length > 1
          )
        );
      },
      {
        message: "Forms must have one confirmation action",
      }
    ),
    style: ComponentBlockStyleSchema.optional(),
    captchaEnabled: z.boolean().default(false).openapi({ effectType: "input" }),
  }),
  children: z.array(FieldBlockSchema),
}).openapi({ ref: "FormBlock" });

export type FormBlock = z.infer<typeof FormBlockSchema>;
