import { BlockType } from "@prisma/client";

export const blockType = "form-block" as const;
export const blockTypeDbName = BlockType.Form;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;
