import { BlockType } from "@prisma/client";

export const blockType = "media-block" as const;
export const blockTypeDbName = BlockType.Media;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;

export const SUPPORTED_VIDEO_PROVIDERS = [
  "youtube",
  "youtu.be",
  "vimeo",
  "loom",
  "zappy",
] as const;
