import "zod-openapi/extend";

import { BlockBaseSchema, BlockIdSchema } from "block-system/types/base";
import { blockType } from "./constants";
import { ZodSchema, z } from "zod";

export const FieldBlockConditionalLogicOperatorSchema = z.union([
  z.literal("exact"),
  z.literal("contains"),
  z.literal("icontains"),
  z.literal("in"),
  z.literal("startswith"),
  z.literal("gte"),
  z.literal("gt"),
  z.literal("lte"),
  z.literal("lt"),
  z.literal("range"),
  z.literal("isEmpty"),
  z.literal("isNotEmpty"),
]);

export type FieldBlockConditionalLogicOperator = z.infer<
  typeof FieldBlockConditionalLogicOperatorSchema
>;

export const FieldBlockConditionalLogicItemSchema = z.object({
  fieldId: z.string(),
  operator: FieldBlockConditionalLogicOperatorSchema,
  value: z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.array(z.union([z.string(), z.number(), z.boolean()])),
  ]),
});

export type FieldBlockConditionalLogicItem = z.infer<
  typeof FieldBlockConditionalLogicItemSchema
>;

const conditionIndexSchema = z.number().int().nonnegative();
type ConditionIndex = z.infer<typeof conditionIndexSchema>;

export type ConditionGrouping = {
  and?: (ConditionIndex | ConditionGrouping)[];
  or?: (ConditionIndex | ConditionGrouping)[];
};

export const conditionGroupingSchema: ZodSchema<ConditionGrouping> = z
  .lazy(() =>
    z.union([
      z.object({
        and: z.array(z.union([conditionIndexSchema, conditionGroupingSchema])),
      }),
      z.object({
        or: z.array(z.union([conditionIndexSchema, conditionGroupingSchema])),
      }),
    ])
  )
  .openapi({ ref: "ConditionalLogicGrouping" });
// Without manually registering, openapi fails to build because of circular references

export type ConditionalLogicGrouping = z.infer<typeof conditionGroupingSchema>;

export type ConditionalLogicGroupingOperator = "and" | "or";
export type ConditionalLogicGroupingOperators =
  ConditionalLogicGroupingOperator[];

// This value is something that seemed a "good" limit.
// If we hear from customers for more, we can increase it.
export const MAX_CONDITIONS_ON_FIELD = 5;

export const FieldBlockConfigBaseSchema = z.object({
  id: BlockIdSchema.optional(),
  name: z.string(),
  nameAutoGenerated: z
    .boolean()
    .default(false)
    .optional()
    .openapi({ effectType: "input" }),
  required: z.boolean(),
  hidden: z.boolean().optional(),
  label: z.string(),
  placeholder: z.string().optional(),
  helpText: z.string().optional(),
  defaultValue: z.string().optional(),
  tablesFieldKey: z.string().optional(),
  conditionalLogic: z
    .array(FieldBlockConditionalLogicItemSchema)
    .max(MAX_CONDITIONS_ON_FIELD)
    .default([])
    .openapi({ effectType: "input" }),
  conditionalLogicGrouping: conditionGroupingSchema.optional(),
  readOnly: z.boolean().default(false).optional(),
});

export const FieldBlockStandardConfigSchema = FieldBlockConfigBaseSchema.extend(
  {
    inputType: z.union([
      z.literal("text"),
      z.literal("textarea"),
      z.literal("url"),
    ]),
  }
);

export const FieldBlockDropDownConfigSchema = FieldBlockConfigBaseSchema.extend(
  {
    inputType: z.literal("dropdown"),
    options: z.string().optional(),
    multiSelect: z.boolean().optional(),
    tableId: z.string().optional(),
    tableFieldId: z.number().or(z.string()).optional(),
  }
);

export const FieldBlockNumberConfigSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("number"),
  min: z.string().optional(),
  max: z.string().optional(),
});

export const FieldBlockYesNoConfigSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("yes-no"),
});

export const FieldBlockEmailConfigSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("email"),
  blockFreeEmailProviders: z.boolean().optional(),
  restrictDomains: z.boolean().optional(),
  allowedDomains: z.string().optional(),
});

export const DateFormatOptions = [
  {
    label: "YYYY-MM-DD",
    value: "yyyy-MM-dd",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-dd-yyyy",
  },
  {
    label: "DD-MM-YYYY",
    value: "dd-MM-yyyy",
  },
];

export const FieldBlockDatepickerConfigSchema =
  FieldBlockConfigBaseSchema.extend({
    inputType: z.literal("date-picker"),
    dateFormat: z.string().optional(),
    includeTime: z.boolean().default(false).openapi({ effectType: "input" }),
    defaultToNow: z.boolean().default(false).openapi({ effectType: "input" }),
  });

export const PhoneNumberFormatOptions = [
  {
    label: "(555) 555-1212",
    value: "phone_number_format_1",
  },
  {
    label: "+1 (555) 555-1212",
    value: "phone_number_format_2",
  },
  {
    label: "+44 55 1234 5678",
    value: "phone_number_format_3",
  },
];

export const FieldBlockPhoneNumberConfigSchema =
  FieldBlockConfigBaseSchema.extend({
    inputType: z.literal("phone-number"),
    phoneNumberFormat: z
      .string()
      .default("phone_number_format_1")
      .openapi({ effectType: "input" }),
    defaultCountry: z
      .string()
      .length(2)
      .optional()
      .default("US")
      .openapi({ effectType: "input" }),
  });

export const FieldBlockCheckboxConfigSchema = FieldBlockConfigBaseSchema.extend(
  {
    inputType: z.literal("checkbox"),
    label: z.string().optional().default("").openapi({ effectType: "input" }),
    text: z.string(),
    defaultChecked: z.boolean().default(false).openapi({ effectType: "input" }),
  }
);

export const FieldBlockFileUploadSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("file-upload"),
  allowedFileTypes: z.string().optional(),
  allowedFileSize: z.number().optional(),
});

export const FieldBlockAIFormulaSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("ai-formula"),
});

export const FieldBlockMultipleLinkedRecordsSchema =
  FieldBlockConfigBaseSchema.extend({
    inputType: z.literal("multiple-linked-record"),
  });

export const FieldBlockLinkedRecordSchema = FieldBlockConfigBaseSchema.extend({
  inputType: z.literal("linked-record"),
});

export const CurrencyFormatOptions = [
  {
    label: "Default ($20.11)",
    value: "usd1",
  },
  {
    label: "USD ($20.11)",
    value: "usd2",
  },
  {
    label: "Euro (20.11€)",
    value: "euro1",
  },
  {
    label: "Pound (£20.11)",
    value: "pound1",
  },
  {
    label: "Yen (¥20.11)",
    value: "yen1",
  },
] as const;

export const CurrencyDecimalOptions = [
  {
    label: "00",
    value: 0,
  },
  {
    label: "0.00",
    value: 2,
  },
] as const;

export const FieldBlockCurrencyConfigSchema = FieldBlockConfigBaseSchema.extend(
  {
    inputType: z.literal("currency"),
    decimals: z
      .number()
      .default(CurrencyDecimalOptions[0].value)
      .openapi({ effectType: "input" }),
    currencyFormat: z
      .string()
      .default(CurrencyFormatOptions[0].value)
      .openapi({ effectType: "input" }),
  }
);

export const FieldBlockConfigSchema = z.union([
  FieldBlockStandardConfigSchema,
  FieldBlockDropDownConfigSchema,
  FieldBlockNumberConfigSchema,
  FieldBlockYesNoConfigSchema,
  FieldBlockEmailConfigSchema,
  FieldBlockDatepickerConfigSchema,
  FieldBlockPhoneNumberConfigSchema,
  FieldBlockCheckboxConfigSchema,
  FieldBlockFileUploadSchema,
  FieldBlockCurrencyConfigSchema,
  FieldBlockAIFormulaSchema,
  FieldBlockMultipleLinkedRecordsSchema,
  FieldBlockLinkedRecordSchema,
]);

export const FieldBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: FieldBlockConfigSchema,
}).openapi({ ref: "FieldBlock" });
