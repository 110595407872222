import "zod-openapi/extend";

import { Zap } from "server/services/zapier/zaps/types/types";
import { z } from "zod";

export const formSubmissionZapCreateSchema = z.object({
  type: z.literal("form-submission"),
  formId: z.string().cuid(),
});
export const newRecordZapCreateSchema = z.object({
  type: z.literal("new-record"),
  tableId: z.string(),
});
export const recordUpdatedCreateZapSchema = z.object({
  type: z.literal("record-updated"),
  tableId: z.string(),
});
export const recordFieldUpdatedCreateZapSchema = z.object({
  type: z.literal("record-field-updated"),
  tableId: z.string(),
  fieldId: z.string(),
  fieldValue: z.string().or(z.boolean()).optional(),
});
export const buttonClickedZapSchema = z.object({
  type: z.literal("button-click"),
  blockId: z.string(),
});
export const paymentProcessedZapSchema = z.object({
  type: z.literal("stripe-payment-processed"),
  blockId: z.string(),
});
export const createZapInput = z.union([
  formSubmissionZapCreateSchema,
  newRecordZapCreateSchema,
  recordUpdatedCreateZapSchema,
  recordFieldUpdatedCreateZapSchema,
  buttonClickedZapSchema,
  paymentProcessedZapSchema,
]);

export const zapRelations = z.object({
  projects: z.string().cuid().array().optional(),
  pages: z.string().cuid().array().optional(),
  tables: z.string().array().optional(),
  forms: z.string().cuid().array().optional(),
  blockActions: z.string().cuid().array().optional(),
});
export type ZapRelations = z.infer<typeof zapRelations>;

export const ZapOutputSchema = z
  .object({
    id: z.string(),
    title: z.string(),
    zdl: z.record(z.string(), z.any()).optional(),
    paused: z.boolean(),
    relations: zapRelations.optional(),
  })
  .openapi({ ref: "Zap" });

export type ZapOutput = z.infer<typeof ZapOutputSchema>;

export const zapToZapOutput = (
  zap: Zap,
  relations?: ZapRelations
): ZapOutput => ({
  id: zap.id,
  title: zap.title ?? "Untitled zap",
  paused: !zap.is_enabled,
  zdl: zap.current_version?.zdl ?? zap.draft?.zdl,
  relations,
});

export const zapRemovalOptions = ["disable-zap", "delete-zap"] as const;
export type ZapRemovalOption = (typeof zapRemovalOptions)[number];

const zapSearchBase = z.object({
  useCache: z
    .boolean()
    .optional()
    .default(true)
    .openapi({ effectType: "input" }),
});
export const zapSearchSchema = z.union([
  zapSearchBase.extend({
    projectId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    projectIds: z.string().cuid().array(),
  }),
  zapSearchBase.extend({
    blockId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    blockActionId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    pageId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    formId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    tableId: z.string(),
  }),
  zapSearchBase.extend({
    buttonId: z.string().cuid(),
  }),
  zapSearchBase.extend({
    stripePaymentId: z.string().cuid(),
  }),
]);
